import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import ChampsBalanceCard from "../components/BalanceCard/ChampsBalanceCard";
import TotalEthReward from "../components/TotalEthReward/TotalEthReward";
import SearchCard from "../components/SearchCard/SearchCard";
import DetailsCard from "../components/DetailsCard/DetailsCard";
import YourBalance from "../components/YourBalance/YourBalance";
import Footer from "../components/Footer/Footer";
// import { ethers } from "ethers";
// import toast from "react-hot-toast";
// import champsAbi from "../abis/champsAbi.json";
import {
  adminApprove,
  adminSignature,
  getTotalDistributedChamps,
} from "../Services/Service";
import ClaimEthCard from "../components/ClaimEthCard/ClaimEthCard";
import Tokenomics from "../components/Tokenomics/Tokenomics";
import {
  useReadContract,
  useClient,
  useAccount,
  useWriteContract,
  useSignMessage,
} from "wagmi";
import {
  useEthersProvider,
  useEthersSigner,
} from "../Services/ProviderExtractor";
import { formatEther, keccak256, parseUnits } from "viem";
import toast from "react-hot-toast";
import { AppConstant } from "../constant/constant";
import { ethers } from "ethers";
import { AbiCoder, arrayify, solidityPack } from "ethers/lib/utils";

const Home = () => {
  // // let [provider, setProvider] = useState(null);
  // // const [claimrewards, setClaimRewards] = useState(null);
  // // const [showrewards, setShowRewards] = useState(null);
  // // const [walletConnected, setWalletConnected] = useState(false);
  const [totalRewardsDistributed, setTotalRewardsDistributed] = useState(0);
  const [ethTokenBalance, setEthTokenBalance] = useState(0);
  // // useState(null);
  // // const [binancePegETHPrice, setBinancePegETHPrice] = useState(1);
  // // const [userWalletDividendsInfo, setUserWalletDividendsInfo] = useState(null);
  // // const [balanceInfo, setBalanceInfo] = useState(0);
  // const [CurrentAdd, setCurrentAdd] = useState("0X");
  // const [balance, setBalance] = useState();
  // // const [contractName, setContractName] = useState(null);
  // const [contractSymbol, setContractSymbol] = useState(null);
  // const [contractSupply, setContractSupply] = useState(null);
  // // const [show, setShow] = useState(false);
  // // const [Status, setStatus] = useState(null);
  const [UserWallet, setUserWallet] = useState(null);
  // // const [showPopup, setShowPopup] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);

  const smartContractAddress = "0x0144C22648a1F07Be74039F0Dbbc4DEE4F56ee0F";
  const wethContractAddress = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

  const account = useAccount();

  const {
    data: champsBalance,
    isError: champsBalanceError,
    isLoading: champsBalanceIsLoading,
  } = useReadContract({
    ...AppConstant.champsContractConfig,
    functionName: "balanceOf",
    args: [UserWallet || account.address],
  });

  const {
    data: calculateETHClaimable,
    isError: calculateETHClaimableIsError,
    isLoading: calculateETHClaimableIsLoading,
  } = useReadContract({
    ...AppConstant.champsContractConfig,
    functionName: "calculateETHClaimable",
    args: [UserWallet || account.address],
  });

  const {
    data: totalEthReflections,
    isError: totalEthReflectionsIsError,
    isLoading: totalEthReflectionsIsLoading,
  } = useReadContract({
    ...AppConstant.champsContractConfig,
    functionName: "totalEthReflections",
    args: [UserWallet || account.address],
  });

  const {
    data: totalSupply,
    isError: totalSupplyError,
    isLoading: totalSupplyIsLoading,
  } = useReadContract({
    ...AppConstant.champsContractConfig,
    functionName: "totalSupply",
    // args: [UserWallet || account.address],
  });

  const {
    data: witeHash,
    isPending: writeIsPending,
    writeContract,
  } = useWriteContract();

  // const {
  //   data: ethBalance,
  //   isLoading: ethBalanceIsLoading,
  //   isError: ethBalanceIsError,
  // } = useReadContract({
  //   address: account.address,
  //   functionName: "getBalance",
  //   args: [],
  //   abi: [
  //     {
  //       name: "getBalance",
  //       type: "function",
  //       stateMutability: "view",
  //       inputs: [],
  //       outputs: [{ type: "uint256" }],
  //     },
  //   ],
  // });

  useEffect(() => {
    fetchNewData();
    // console.log("triggered");
  }, [account, UserWallet]);

  const {
    signMessage: claimEthSignMessage,
    data: claimEthData,
    isLoading: claimEthIsLoading,
    isError: claimEthIsError,
  } = useSignMessage({
    onSuccess(data, variables) {
      console.log("Message signed successfully:", data);
      console.log("Original message:", variables.message);
      // You can verify the signature or perform additional actions here
    },
    onError(error) {
      console.error("Error signing message:", error);
    },
  });

  const etherProvider = useEthersProvider();
  const etherSigner = useEthersSigner();

  const claimRewardPointEth = async () => {
    if (claimLoading) return;

    // try {
    if (!account.isConnected) {
      toast.error(
        "Wallet not connected. Please connect your wallet to proceed."
      );
      setClaimLoading(false);
      return;
    }

    setClaimLoading(true);

    const formattedCalculateEthClaimable = formatEther(
      calculateETHClaimable?.toString() ?? "0"
    );

    console.log(formattedCalculateEthClaimable);

    if (parseFloat(formattedCalculateEthClaimable) <= 0) {
      toast.error("Insufficient balance. You have no ETH available to claim.");
      setClaimLoading(false);
      return;
    }

    try {
      // const signer = await etherProvider.getSigner();
      const signer = etherSigner;
      // const address = await signer.getAddress();
      // console.log(signer);
      const token = new ethers.Contract(
        AppConstant.CHAMPS_CONTRACT_ADDRESS,
        AppConstant.CHAMPS_ABI,
        signer
      );

      const amount = parseUnits(formattedCalculateEthClaimable, 18);
      const timeNow = Math.floor(Date.now() / 1000) + 60;
      const abiCoder = new AbiCoder();
      const nonce = await token.functions.userNonce(account.address);
      console.log(nonce.toString(), "User NOUNCE");

      // //Create the message hash by encoding the addr1 address and amount
      // const messageHash2 = abiCoder.encode(
      //   ["address", "uint256", "uint256", "uint256"],
      //   [account.address, amount, timeNow, nonce.toString()]
      // );

      // //Create the message hash by encoding the addr1 address and amount
      // const messageHash2 = solidityPack(
      //   ["address", "uint256", "uint256", "uint256"],
      //   [account.address, amount, timeNow, nonce.toString()]
      // );

      const messageHash2 = keccak256(
        solidityPack(
          ["address", "uint256", "uint256", "uint256"],
          [account.address, amount, timeNow, nonce?.toString()]
        )
      );

      // const messageHash2 = ethers.utils.defaultAbiCoder.encode(
      //   ["address", "uint256", "uint256", "uint256"],
      //   [account.address, amount, timeNow, nonce.toString()]
      // );

      const body = {
        remainingAmount: calculateETHClaimable?.toString(), //formatted 18 decimal in backend
        userAddress: account.address,
        userNonce: nonce?.toString(),
      };

      console.log("Payload", body);

      const response = await adminApprove(body);

      // console.log(signature);

      const signature = response?.data?.data;

      console.log("signature", signature);

      const claim = await token.claimRewardPointsWithETH(
        messageHash2,
        signature,
        {
          gasLimit: 500000,
        }
      );

      // Wait for confirmation
      const receipt = await claim.wait();

      if (receipt.status === 1) {
        console.log(
          "✅ Transaction Successful! Tx Hash:",
          receipt.transactionHash
        );
        toast.success("Transaction successful!");
      } else {
        console.log("❌ Transaction Failed! Tx Hash:", receipt.transactionHash);
        toast.error("Transaction failed! Please try again.");
      }
      setClaimLoading(false);

      // console.log(claim);
    } catch (error) {
      console.error("Blockchain Error:", error);
      setClaimLoading(false);
      if (error?.code === 4001) {
        // User rejected the transaction in MetaMask
        toast.error("Transaction rejected by the user.");
      } else if (error?.code === -32000) {
        // Gas estimation failed or insufficient funds
        toast.error("Insufficient funds or gas limit too low.");
      } else if (error?.message?.includes("execution reverted")) {
        // Smart contract reverted the transaction
        toast.error("Transaction reverted by the contract.");
      } else if (error?.message?.includes("nonce too low")) {
        // Nonce issue in transaction
        toast.error("Nonce too low, try resubmitting the transaction.");
      } else {
        // Generic error
        toast.error("Something went wrong! Please try again later.");
      }
    }
    setClaimLoading(false);
  };

  // const claimRewardPointEth1 = async () => {
  //   try {
  //     console.log(account.isConnected);

  //     if (!account.isConnected) {
  //       toast.error(
  //         "Wallet not connected. Please connect your wallet to proceed."
  //       );
  //       return;
  //     }

  //     // writeContract({
  //     //   ...AppConstant.champsContractConfig,
  //     //   functionName: "mint",
  //     //   args: [BigInt(tokenId)],
  //     // });

  //     // const signer = await etherProvider.getSigner();
  //     // // const address = await signer.getAddress();
  //     // // console.log(signer);
  //     // const initContract = new ethers.Contract(
  //     //   AppConstant.CHAMPS_CONTRACT_ADDRESS,
  //     //   AppConstant.CHAMPS_ABI,
  //     //   signer
  //     // );

  //     // const formattedCalculateEthClaimable = formatEther(
  //     //   calculateETHClaimable?.toString() ?? "0"
  //     // );

  //     // if (parseInt(formattedCalculateEthClaimable) <= 0) {
  //     //   toast.error(
  //     //     "Insufficient balance. You have no ETH available to claim."
  //     //   );
  //     //   return;
  //     // }

  //     const response = await adminSignature({
  //       userAddress: account.address,
  //       token: "0.1",
  //     });

  //     console.log(response?.data);

  //     // // Call the smart contract function
  //     // const tx = await initContract.claimRewardPointsWithETH();

  //     // toast.info("Transaction submitted! Waiting for confirmation...");

  //     // // Wait for transaction to be confirmed
  //     // const receipt = await tx.wait();

  //     // if (receipt.status === 1) {
  //     //   toast.success("Reward claimed successfully!");
  //     // } else {
  //     //   toast.error("Transaction failed!");
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("Something went wrong please try again later");
  //   }
  // };

  const fetchNewData = async () => {
    // console.log(etherProvider);

    if (UserWallet || account.address) {
      const data = await etherProvider.getBalance(
        UserWallet || account.address
      );
      setEthTokenBalance(data.toString());
    }

    // console.log(data.toString());
  };

  const fetchTotalDistributed = async () => {
    try {
      const response = await getTotalDistributedChamps();
      console.log(response?.data?.data, "Total distributed champs");
      setTotalRewardsDistributed(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTotalDistributed();
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Header
      // connectWallet={() => connectWallet()}
      // walletConnected={walletConnected}
      />
      <Container maxWidth="lg">
        {" "}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column", sm: "column" },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            marginTop: 5,
            mx: {
              xs: 0,
              md: 0,
            },
          }}
        >
          <ChampsBalanceCard
            balance={formatEther(champsBalance?.toString() ?? "0") || "0:00"}
          />
          <ClaimEthCard
            onClick={() => claimRewardPointEth()}
            calculateETHClaimable={
              formatEther(calculateETHClaimable?.toString() ?? "0") || "0:00"
            }
          />
          <TotalEthReward
            totalRewardsDistributed={
              formatEther(totalEthReflections?.toString() ?? 0) || "0:00"
            }
          />
        </Box> */}
        <Grid
          container
          spacing={2}
          sx={{
            mt: {
              xs: 2,
              md: 3,
              lg: 3,
            },
          }}
        >
          <Grid item xs={12} md={4}>
            <ChampsBalanceCard
              balance={formatEther(champsBalance?.toString() ?? "0") || "0:00"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ClaimEthCard
              onClick={() => claimRewardPointEth()}
              calculateETHClaimable={
                formatEther(calculateETHClaimable?.toString() ?? "0") || "0:00"
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TotalEthReward
              totalRewardsDistributed={
                formatEther(totalEthReflections?.toString() ?? 0) || "0:00"
              }
            />
          </Grid>
        </Grid>
        {/* <Stack
          sx={{
            marginTop: "30px",
            marginBottom: "40px",
            paddingX: { md: 0, xs: 0 },
          }}
        > */}
        <Grid
          container
          spacing={2}
          // maxWidth={"100%"}
          // maxHeight={"500px"}
          mt={"5px"}
        >
          <Grid item xs={12} md={4}>
            <Tokenomics />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchCard
              onChange={(e) => setUserWallet(e.target.value)}
              //   onClick={() => init()}
              onClick={() => fetchNewData()}
              isConnected={account.isConnected}
            />
            <DetailsCard
              contractSupply={
                formatEther(totalSupply?.toString() ?? "0") || "0:00"
              }
              // contractSymbol={contractSymbol}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <YourBalance
              ethTokenBalance={
                formatEther(ethTokenBalance?.toString() ?? "0") || "0:00"
              }
              // balance={balance}
              balance={formatEther(champsBalance?.toString() ?? "0") || "0:00"}
              claimrewards={
                formatEther(calculateETHClaimable?.toString() ?? "0") || "0:00"
              }
              // currentAdd={CurrentAdd}
              currentAdd={UserWallet || account?.address}
              // totalRewardsDistributed={totalRewardsDistributed}
              totalRewardsDistributed={totalRewardsDistributed}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}
            lg={4}
            pr={{
              xs: 0,
              md: 2,
            }}
            mb={{
              xs: 2,
            }}
          >
            <Tokenomics />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            pr={{ xs: 0, md: 2 }}
            mb={{
              xs: 2,
            }}
          >
            <SearchCard
              onChange={(e) => setUserWallet(e.target.value)}
              //   onClick={() => init()}
              onClick={() => fetchNewData()}
              isConnected={account.isConnected}
            />
            <DetailsCard
              contractSupply={
                formatEther(totalSupply?.toString() ?? "0") || "0:00"
              }
              // contractSymbol={contractSymbol}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <YourBalance
              ethTokenBalance={
                formatEther(ethTokenBalance?.toString() ?? "0") || "0:00"
              }
              // balance={balance}
              claimrewards={
                formatEther(calculateETHClaimable?.toString() ?? "0") || "0:00"
              }
              // currentAdd={CurrentAdd}
              currentAdd={UserWallet || account?.address}
              // totalRewardsDistributed={totalRewardsDistributed}
              totalRewardsDistributed={
                formatEther(totalEthReflections?.toString() ?? 0) || "0:00"
              }
            />
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "block",
                lg: "none",
              },
            }}
          >
            <Footer />
          </Grid> */}
        </Grid>
        {/* </Stack> */}
      </Container>
      <Box
        sx={
          {
            // display: {
            //   xs: "none",
            //   lg: "block",
            // },
          }
        }
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
