import React from "react";
import "./balance-card.styles.css";
import { Box, Typography } from "@mui/material";

const ChampsBalanceCard = ({ balance }) => {
  return (
    <div className="champs-balance-card">
      {/* <img src="/Images/champs-card.png" alt="" className="backgroud-img" /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <h1 className="card-header">CHAMPS Balance</h1>
        <img
          src="/Images/champ-w.png"
          alt=""
          style={{
            width: "67px",
            height: "62px",
          }}
        />
      </div>

      <Box display={"flex"} alignItems={"center"} paddingX={2}>
        <img src="/Images/champ-w.png" alt="" style={{ width: "45px" }} />
        <Typography fontSize={22}>
          {" "}
          {/* {Number(balance / Math.pow(10, 18)).toFixed(2) +
                          " CHAMPS"} */}
          {/* {balance ? Number(balance / Math.pow(10, 18)).toFixed(2) : "0.00"} */}
          {balance ? Number(balance) : "0:00"}
        </Typography>
        <Typography variant="caption" fontSize={18} marginLeft={1}>
          CHAMPS
        </Typography>
      </Box>
    </div>
  );
};

export default ChampsBalanceCard;
