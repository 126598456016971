import axios from "axios"



export const api = axios.create({
  baseURL:process.env.REACT_APP_BACKEND_URL,
  headers:{
    "Content-Type":"application/json",
  }
})

// instance.interceptors.request.use(
//   async (config) => {
//     // Get the Firebase user token
//     const user = auth.currentUser;
//     if (user) {
//       const token = await user.getIdToken();
//       // Add the Firebase token to the request headers
//       config.headers['Authorization'] = `${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
// });
