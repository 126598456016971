import champsAbi from "../abis/champsAbi.json";

export class AppConstant {
  static WETH_TOKEN_ADDRESS = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  static CHAMPS_CONTRACT_ADDRESS = "0xB86c66d8789Aa91BAB9666922618b86C260a2659";
  // "0x0144C22648a1F07Be74039F0Dbbc4DEE4F56ee0F";
  // static CHAMPS_ABI = champsAbi;

  static get CHAMPS_ABI() {
    return champsAbi; // Getter ensures initialization at runtime
  }

  static get champsContractConfig() {
    return {
      address: AppConstant.CHAMPS_CONTRACT_ADDRESS,
      abi: AppConstant.CHAMPS_ABI,
    };
  }
}
