const CustomLogoAvatar = ({ address, ensImage, size }) => {
  //   const color = generateColorFromAddress(address);
  return (
    <img
      src={"./Images/champ-w.png"}
      //   src={ensImage}
      width={size}
      height={size}
      style={{
        borderRadius: 999,
        background: "#11b1f4",
        // filter: "blur(100px)",
      }}
    />
  );
};

export default CustomLogoAvatar;
