// import axios from "axios";

import { api } from "../Utils/axiosConfig";

const token = sessionStorage.getItem("token");
const getAuthHeaders = () => {
  const token = sessionStorage.getItem("token");

  if (token) {
    console.log("Access token available");
    return {
      headers: {
        Authorization: `${token}`,
      },
    };
  } else {
    // throw new Error("Access token not available");
    console.log("Access token not available");
  }
};

export const adminApprove = async (data) =>
  await api.post("/blockchainTransaction/admin-approve-eth", data);

export const getTotalDistributedChamps = async () =>
  await api.get("/wallet-transaction/total-distributed-champs");

export const getMarketCap = async (contractAddress) =>
  await api.get(
    // `https://api.geckoterminal.com/v2/markets/base/pairs/${contractAddress}`
    `https://api.geckoterminal.com/api/v2/networks/base/tokens/0xB86c66d8789Aa91BAB9666922618b86C260a2659`
    // `https://api.geckoterminal.com/tokens/info_recently_updated?contract_address=${contractAddress}`
  );

// export const adminSignatureEth = async (data) =>
//   await api.post("/blockchainTransaction/eth-admin-signature", data);
