import { ethers, providers } from "ethers";
import { useMemo } from "react";
import { useClient } from "wagmi";
import { useConnectorClient, useConfig } from "wagmi";

export function clientToProvider(client) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === "fallback")
    return new providers.FallbackProvider(
      (transport.transports ?? []).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    );
  return new providers.JsonRpcProvider(transport.url, network);
}

// Hook to convert a viem Client to an ethers.js Provider
export function useEthersProvider() {
  const client = useClient();

  return useMemo(
    () => (client ? clientToProvider(client) : undefined),
    [client]
  );

  // return useMemo(() => {
  //   if (typeof window !== "undefined" && window.ethereum) {
  //     // Create a Web3Provider using the browser's Ethereum provider (e.g., MetaMask)
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     return provider;
  //   } else if (client) {
  //     // Fallback to the client provider if window.ethereum is not available
  //     return clientToProvider(client);
  //   } else {
  //     return undefined;
  //   }
  // }, [client]);
}

export function clientToSigner(client) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export function useEthersSigner({ chainId } = {}) {
  const { data: client } = useConnectorClient({ chainId });
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}
