import axios from "axios";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getMarketCap } from "../../Services/Service";
import { formatEther } from "viem";
import { AppConstant } from "../../constant/constant";

const DetailsCard = ({ contractSupply, contractSymbol }) => {
  const [marketCap, setMarketCap] = useState(0);
  const [tokenHolder, setTokenHolder] = useState(0);

  // Function to get market cap and price from GeckoTerminal
  const fetchData = async () => {
    try {
      const response = await getMarketCap();
      const data = response.data.data.attributes;

      const priceUSD = data.price_usd; // Price in USD
      const totalSupply = data.total_supply; // Total supply (in the smallest unit)

      // Convert total supply to actual tokens (accounting for 18 decimals)
      const actualTotalSupply = parseInt(
        formatEther(totalSupply?.toString() ?? "0")
      );

      console.log("Price (USD):", priceUSD);
      console.log("Total Supply (adjusted for decimals):", actualTotalSupply);

      // Calculate Market Cap
      const marketCap = priceUSD * actualTotalSupply;
      console.log("Market Cap (USD):", marketCap);

      setMarketCap(marketCap);
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  // const getTotalHolders = async () => {
  //   try {
  //     const response = await axios.get(`https://api.basescan.org/api`, {
  //       params: {
  //         module: "token",
  //         action: "tokenholdercount",
  //         contractaddress: AppConstant.CHAMPS_CONTRACT_ADDRESS,
  //         apikey: "KBC83QZNCNR87ZNIV85IHI2DQEM211BJ3N",
  //       },
  //     });

  //     if (response.data.status === "1") {
  //       console.log(`Total Holders: ${response.data.result}`);
  //       const holder = response.data.result;
  //     } else {
  //       throw new Error(
  //         response.data.message || "Failed to fetch total holders"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching token holders:", error.message);
  //     // throw error;
  //   }
  // };

  const getTokenHoldersFromTransfers = async () => {
    try {
      const response = await axios.get(`https://api.basescan.org/api`, {
        params: {
          module: "account",
          action: "tokentx",
          contractaddress: AppConstant.CHAMPS_CONTRACT_ADDRESS,
          apikey: "", //get from baseScan
          startblock: 0,
          endblock: 99999999,
          sort: "asc",
        },
      });

      if (response.data.status === "1") {
        const transfers = response.data.result;
        const uniqueHolders = new Set();

        transfers.forEach((tx) => {
          uniqueHolders.add(tx.to);
          uniqueHolders.add(tx.from);
        });

        console.log(`Total Unique Token Holders: ${uniqueHolders.size}`);
        // return uniqueHolders.size;
        setTokenHolder(uniqueHolders.size);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching token holders:", error.message);
    }
  };

  useEffect(() => {
    // fetchData();
    // // getTotalHolders();
    // getTokenHoldersFromTransfers();
  }, []);

  return (
    <Box
      sx={{
        background: "#0F5176",
        width: "100%",
        height: "300px",
        borderRadius: "20px",
        marginTop: 2,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Total Supply
        </Typography>
        {/* <Typography> 2.00 Billion</Typography> */}
        <Typography>{contractSupply}</Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Market Cap
        </Typography>
        <Typography>
          {" "}
          {/* {contractSupply
            ? Number(contractSupply / Math.pow(10, 18)).toFixed(2)
            : "0.00"} */}
          {/* {contractSupply} */}
          {marketCap || "0:00"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Reward Holders
        </Typography>
        {/* <Typography>{contractSymbol ? contractSymbol : 0}</Typography> */}
        <Typography>{tokenHolder || "0"}</Typography>
      </Box>

      <Box display={"flex"} flexDirection={"column"} padding={2}>
        {" "}
        <Typography fontWeight={600} fontSize={"14px"}>
          Website
        </Typography>
        <Typography variant="caption">https://www.cryptochamps.io</Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} paddingX={2}>
        {" "}
        <Typography fontWeight={600} fontSize={"14px"}>
          Chart
        </Typography>
        <Typography
          variant="caption"
          style={{
            whiteSpace: "nowrap",
          }}
          width={"90%"}
        >
          --------------------------------------
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailsCard;
