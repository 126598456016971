/* eslint-disable jsx-a11y/anchor-is-valid */

import "./App.css";
// import NavBar from "./components/NavBar";
// import ethereum from "../src/asset/image/Ethereum_Flatline.svg";
// import { useEffect, useState } from "react";
// import Web3 from "web3";
// // import Web3Modal from "web3modal";
// // import WalletConnectProvider from "@walletconnect/web3-provider";
// import { contractABI } from "./contractABI";
// // import ContractSupplyCard from "./components/ContractSupplyCard";
// // import BalanceCard from "./components/BalanceCard";
// import Header from "./components/Header/Header";
// import ChampsBalanceCard from "./components/BalanceCard/ChampsBalanceCard";
// import ClaimEthCard from "./components/ClaimEthCard/ClaimEthCard";
// import TotalEthReward from "./components/TotalEthReward/TotalEthReward";
// import { Box, Container, Grid, Stack } from "@mui/material";
// import SearchCard from "./components/SearchCard/SearchCard";
// import DetailsCard from "./components/DetailsCard/DetailsCard";
// import YourBalance from "./components/YourBalance/YourBalance";
// import Tokenomics from "./components/Tokenomics/Tokenomics";
// import toast, { Toaster } from "react-hot-toast";
// import Footer from "./components/Footer/Footer";
// import { ethers } from "ethers";
// // import Footer from "./components/Footer/Footer";
// import champsAbi from "./abis/champsAbi.json";
// import { adminSignature } from "./Services/Service";

import "@rainbow-me/rainbowkit/styles.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, http } from "wagmi";
import { base, mainnet, optimism } from "wagmi/chains";
import {
  RainbowKitProvider,
  AvatarComponent,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import Home from "./page/Home";
import { Toaster } from "react-hot-toast";
import CustomLogoAvatar from "./components/Common/CustomAvatar";
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { createConfig } from "wagmi";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet],
    },
    {
      groupName: "Popular",
      wallets: [
        coinbaseWallet,
        walletConnectWallet,
        trustWallet,
        rainbowWallet,
      ],
    },
  ],
  {
    appName: "Cryptochamps DApp",
    projectId: process.env.REACT_APP_RAINBOW_KIT_PROJECTID,
    // chains: [base],
    // transports: {
    //   [base.id]: http(),
    //   // [mainnet.id]: http()
    // },
    appDescription: "",
  }
);

const config1 = createConfig({
  connectors,
  chains: [base],
  transports: {
    [base.id]: http(),
    // [mainnet.id]: http()
  },
  ssr: true,
});

/* New API that includes Wagmi's createConfig and replaces getDefaultWallets and connectorsForWallets */
// const config = getDefaultConfig({
//   // wallets: [
//   //   {
//   //     groupName: "Wallets",
//   //     wallets: [
//   //       metaMaskWallet,
//   //       coinbaseWallet,
//   //       walletConnectWallet,
//   //       trustWallet,
//   //       rainbowWallet,
//   //     ],
//   //   },
//   // ],
//   // wallets: [
//   //     metaMaskWallet,
//   //     coinbaseWallet,
//   //     walletConnectWallet,
//   //     trustWallet,
//   //     rainbowWallet,
//   // ],
//   appName: "Cryptochamps DApp",
//   projectId: "3c365ffedb64b82ed0403c5d5e9b2486",
//   chains: [base],
//   transports: {
//     [base.id]: http(),
//     // [mainnet.id]: http()
//   },
//   // wallets: [
//   //   metaMaskWallet,
//   //   coinbaseWallet,
//   //   walletConnectWallet,
//   //   trustWallet,
//   //   rainbowWallet,
//   // ],
//   ssr: true,
// });

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config1}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider avatar={CustomLogoAvatar}>
          <Toaster />
          <Home />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
